define("discourse/plugins/discourse-topic-trade-buttons/discourse/connectors/topic-above-post-stream/trade-buttons", ["exports", "@ember/application", "discourse/lib/ajax", "I18n"], function (_exports, _application, _ajax, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      clickSoldButton(topic) {
        const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
        return dialog.yesNoConfirm({
          message: _I18n.default.t("topic_trading.mark_as_sold_confirm"),
          didConfirm: () => {
            (0, _ajax.ajax)("/topic/sold", {
              type: "PUT",
              data: {
                topic_id: topic.id
              }
            }).then(result => {
              topic.set("title", result.topic.title);
              topic.set("fancy_title", result.topic.fancy_title);
              topic.set("archived", result.topic.archived);
            }).catch(() => {
              dialog.alert({
                message: _I18n.default.t("topic_trading.error_while_marked_as_sold")
              });
            });
          }
        });
      },
      clickPurchasedButton(topic) {
        const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
        return dialog.yesNoConfirm({
          message: _I18n.default.t("topic_trading.mark_as_purchased_confirm"),
          didConfirm: () => {
            (0, _ajax.ajax)("/topic/purchased", {
              type: "PUT",
              data: {
                topic_id: topic.id
              }
            }).then(result => {
              topic.set("title", result.topic.title);
              topic.set("fancy_title", result.topic.fancy_title);
              topic.set("archived", result.topic.archived);
            }).catch(() => {
              dialog.alert({
                message: _I18n.default.t("topic_trading.error_while_marked_as_purchased")
              });
            });
          }
        });
      },
      clickExchangedButton(topic) {
        const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
        return dialog.yesNoConfirm({
          message: _I18n.default.t("topic_trading.mark_as_exchanged_confirm"),
          didConfirm: () => {
            (0, _ajax.ajax)("/topic/exchanged", {
              type: "PUT",
              data: {
                topic_id: topic.id
              }
            }).then(result => {
              topic.set("title", result.topic.title);
              topic.set("fancy_title", result.topic.fancy_title);
              topic.set("archived", result.topic.archived);
            }).catch(() => {
              dialog.alert({
                message: _I18n.default.t("topic_trading.error_while_marked_as_exchanged")
              });
            });
          }
        });
      },
      clickCancelledButton(topic) {
        const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
        return dialog.yesNoConfirm({
          message: _I18n.default.t("topic_trading.mark_as_cancelled_confirm"),
          didConfirm: () => {
            (0, _ajax.ajax)("/topic/cancelled", {
              type: "PUT",
              data: {
                topic_id: topic.id
              }
            }).then(result => {
              topic.set("title", result.topic.title);
              topic.set("fancy_title", result.topic.fancy_title);
              topic.set("archived", result.topic.archived);
            }).catch(() => {
              dialog.alert({
                message: _I18n.default.t("topic_trading.error_while_marked_as_cancelled")
              });
            });
          }
        });
      }
    }
  };
});